import { IPasseiosLocalApp } from './../interfaces/IPasseiosLocalApp';
import { HttpHeaders } from '@angular/common/http';
import * as CryptoJS from "crypto-js";

import { environment } from './../environments/environment';
import { HttpAppService } from './../services/http-app.service';
import { IResultHttpApp } from '../interfaces/IResultHttpApp';
import { ConstantsApp } from '../shared/constantsapp';


export abstract class BaseAppService<T> {

  urlBase: string = '';

  public _arrayPasseiosLocal: IPasseiosLocalApp[] = new Array<IPasseiosLocalApp>();

  constructor(
    public url: string,
    public http: HttpAppService,
  ) {
    this.urlBase = `${environment.url_api}/${this.url}`;
  }

  public GetAll(flNaoUsarSpinner?: number): Promise<IResultHttpApp> {
    return this.http.get(this.urlBase, flNaoUsarSpinner ? flNaoUsarSpinner : undefined);
  }

  public GetById(uid: string, flNaoUsarSpinner?: number): Promise<IResultHttpApp> {
    return this.http.get(`${this.urlBase}/${uid}`, flNaoUsarSpinner ? flNaoUsarSpinner : undefined);
  }

  async GetQuery(query: string, flNaoUsarSpinner?: number, nmregiaoTuristicaSelecionada?: string): Promise<IResultHttpApp> {
    // Troca caracter "%" por "MMM" para não dar problema na rota
    query = query.replace(/%/g, 'MMM');
    // criptografa a query
    let _nmRota: string = 'query'
    // if (nmregiaoTuristicaSelecionada && nmregiaoTuristicaSelecionada != '') {
    //   // rota especial para Passeios - para mostrar no log na API as regioes de busca e de localizacao do usuario
    //   ROTA QUERYCOMLOG NAO EXISTE MAIS POIS FOI SUBSTITUIDA POR getPasseiosFormatadosRegiao
    //   _nmRota = 'queryComLog'
    // }
    let _queryCript = await this.Criptografar(query)
    if (_queryCript) {
      // console.log(_queryCript);
      if (_queryCript.indexOf("%") > -1) {
        // se tem caracter que não vai funcionar, "%" por exemplo, não usa a query criptografada
        return this.http.get(`${this.urlBase}/${_nmRota}/${query}`, flNaoUsarSpinner ? flNaoUsarSpinner : undefined, undefined, nmregiaoTuristicaSelecionada);
      } else {
        // manda a query com o "cript" no inicio
        return this.http.get(`${this.urlBase}/${_nmRota}/cript${_queryCript}`, flNaoUsarSpinner ? flNaoUsarSpinner : undefined, undefined, nmregiaoTuristicaSelecionada);
      }
    } else {
      let error = { message: 'Ocorreu um erro inesperado ao acessar o servidor.' +
      ' Por favor, verifique sua internet e tente novamente.'};
      return { success: false, data: {}, error } as IResultHttpApp;
    }
  }

  public post(model: T, flNaoUsarSpinner?: number, headers?: HttpHeaders, msgSpinner?: string): Promise<IResultHttpApp> {
    return this.http.post(this.urlBase,
      model,
      flNaoUsarSpinner ? flNaoUsarSpinner : undefined,
      headers ? headers : undefined,
      msgSpinner && msgSpinner != '' ? msgSpinner : undefined);
  }

  public delete(uid: string): Promise<IResultHttpApp> {
    return this.http.delete(`${this.urlBase}/${uid}`);
  }

  public async GuardarPasseiosLocal(arrayPasseiosLocal: IPasseiosLocalApp[]) {
    this._arrayPasseiosLocal = arrayPasseiosLocal;
  }

  public ObterPasseiosLocal(): IPasseiosLocalApp[] {
    return this._arrayPasseiosLocal;
  }

  // Função para criptografar via cripto-js (permite decriptografia) - usada para trafego client/server
  Criptografar(text: string): string {
    try {
      let encrypted = CryptoJS.AES.encrypt(text, ConstantsApp.secretyKeyCriptoJs.trim()).toString();
      let b64 = CryptoJS.enc.Base64.parse(encrypted);
      encrypted = b64.toString(CryptoJS.enc.Hex);
      return encrypted;
    } catch (e) {
      console.log('Error encrypting data: ' + e)
      return text;
    }
  }

  // Função para decriptografar via cripto-js
  Decriptografar(encryptedData: string): string {
    let data;
    try {
      let b64 = CryptoJS.enc.Hex.parse(encryptedData);
      let bytes = b64.toString(CryptoJS.enc.Base64);
      data = CryptoJS.AES.decrypt(bytes, ConstantsApp.secretyKeyCriptoJs.trim());
      data = data.toString(CryptoJS.enc.Utf8);
      return data;
    } catch (e) {
      console.log('Error decrypting data: ' + e)
      return encryptedData;
    }
  }


}
